import { ReactComponent as BatteryChargingIco } from '../../assets/battery-charging.svg';
import { ReactComponent as SpinnerIco } from '../../assets/spinner3.svg';
import Badge, { DotBadge } from '../../components-v2/badge/badge';
import { rateTypeColor, rateTypeTranslation, SESSION_STATUS_COLORS, sessionStatusTranslation } from '../../components-v2/business-components/domain-mappings';
import { DataTableLayout, sortChecker, sortHandler } from '../../components-v2/data-table/data-table';
import Ico from '../../components-v2/ico';
import Box, { contrastColor, Divider } from '../../components-v2/utils';
import api from '../../services/api';
import { FmChargingSessionListItemDto, FmChargingSessionListResultDto, FmChargingSessionSumsResultDto, RateServiceType, SessionStatus } from '../../services/api-client/csp-api';
import { useAuth } from '../../utils/AuthProvider';
import { endOfDay, getTimezone, startOfDay } from '../../utils/date';
import { formatEnergy, formatPrice } from '../../utils/format';
import useBetterTranslate from '../../utils/translation-utils';
import uniqueLineIdentifier from '../../utils/unique-line-ident';
import { ChargingSessionsListPage, ExportSessionsArgs, FilterParameters, SessionTimeCell } from './charging-sessions-list-page';

const SERVICE_TYPE_ALLOWED_VALUES = [RateServiceType.WORK, RateServiceType.EMPLOYEE, RateServiceType.HOME, RateServiceType.UNDEFINED];

export default function FmChargingSessionsListPage() {
  const { user } = useAuth();

  const SORT_BY_VALUES = ['cardNumber', 'cardComment', 'totalCost'];
  const { _t } = useBetterTranslate('fm-charging-sessions-list-page');

  const sessionsApiCall = async (filter: FilterParameters) => {
    const apiFilter = toSearchApiFilter(filter);
    if (!apiFilter) return undefined;

    const resp = await api.fmChargingSessions.list(apiFilter, { cancelToken: uniqueLineIdentifier() });
    return resp;
  };

  const sumsApiCall = async (filter: FilterParameters) => {
    const apiFilter = toSearchApiFilter(filter);
    if (!apiFilter) return undefined;

    const resp = await api.fmChargingSessions.sums(apiFilter, { cancelToken: uniqueLineIdentifier() });
    return resp;
  };

  const exportApiCall = async (filterParams: FilterParameters, args: ExportSessionsArgs, cancelToken: string) => {
    const apiFilter = toSearchApiFilter(filterParams);
    if (!apiFilter) return undefined;

    return await api.fmChargingSessions.export(
      {
        filter: apiFilter,
        sessionFields: args.sessionFields,
        stationFields: [],
        cardFields: args.cardFields,
        fileType: args.fileType,
        aggregateByStationId: false,
        aggregateByStationLocation: false,
        aggregateByCardId: args.aggregateByCardId,
        aggregateByCardLocation: args.aggregateByCardLocation,
        serviceTypeLabels: args.serviceTypeLabels,
        aggregationLabels: args.aggregationLabels,
        timezone: args.timezone,
      },
      { cancelToken }
    );
  };

  const toSearchApiFilter = (filter: FilterParameters) => {
    if (!filter.clientCode) return undefined;
    let consumedEnergyFrom = filter.energyFrom;
    if (consumedEnergyFrom !== undefined) {
      consumedEnergyFrom = consumedEnergyFrom * 1000;
    }
    let consumedEnergyTo = filter.energyTo;
    if (consumedEnergyTo !== undefined) {
      consumedEnergyTo = consumedEnergyTo * 1000;
    }
    return {
      client: filter.clientCode,
      hierarchyNodeCodes: filter.nodes || [filter.clientCode],
      limit: Number(filter.limit || 20),
      skip: Number(filter.skip || 0),
      from: startOfDay(filter.from),
      to: endOfDay(filter.to),
      entityFilter: filter.entity || [],
      serviceTypes: filter.serviceType as RateServiceType[],
      sortBy: filter.sortBy as any,
      sortDesc: filter.sortDesc === '1',
      energy: { from: consumedEnergyFrom, to: consumedEnergyTo },
      totalCost: { from: filter.totalCostFrom, to: filter.totalCostTo },
      status: filter.status as SessionStatus[],
    };
  };

  const energyApiCall = async (clientCode?: string, from?: string, to?: string, nodes?: string[]) => {
    if (!clientCode) return undefined;
    const resp = await api.fmChargingSessions.energyConsumption(
      {
        client: clientCode,
        hierarchyNodeCodes: nodes || [clientCode],
        from: startOfDay(from),
        to: endOfDay(to),
        timezone: getTimezone(),
      },
      { cancelToken: uniqueLineIdentifier() }
    );
    return resp;
  };

  const searchEntityTags = async (clientCode: string | undefined, txt: string) => {
    if (!clientCode) return [];
    const entityFilterRes = await api.fmChargingSessions.searchFilters({ q: txt, clientCode }, { cancelToken: uniqueLineIdentifier() });
    return entityFilterRes.data;
  };

  return (
    <ChargingSessionsListPage<FmChargingSessionListItemDto, FmChargingSessionListResultDto, FmChargingSessionSumsResultDto>
      pagePrefix={'fm'}
      sortValues={SORT_BY_VALUES}
      totalCostFilter={true}
      purchaseCostFilter={false}
      isStationPublicFilter={false}
      serviceTypes={SERVICE_TYPE_ALLOWED_VALUES}
      sessionsApiCall={sessionsApiCall}
      sumsApiCall={sumsApiCall}
      energyApiCall={energyApiCall}
      searchEntityTags={searchEntityTags}
      exportFieldsConfig={{ showStations: false, showCards: true }}
      exportApiCall={exportApiCall}
      tableLayout={(sessionsResp, filterParams, setFilterParams, sumResp) => {
        const result: DataTableLayout<FmChargingSessionListItemDto> = [
          {
            width: '3fr',
            header: {
              onSort: sortHandler('cardNumber', setFilterParams),
              sorting: sortChecker('cardNumber', filterParams),
              testId: 'header_cardNumber',
              node: () => (
                <Box gap='xs' kind={'vflex'}>
                  <Box fw='800'>{_t('Badge Number')}</Box>
                  <Box>{_t('Badge Label')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box gap='xs' kind={'vflex'}>
                  <Box fw='800' testId='el_cardNumber'>
                    {record.cardNumber || '-'}
                  </Box>
                  <Box testId='el_cardLabel'>{record.cardLabel || '-'}</Box>
                </Box>
              ),
            },
          },
          {
            width: '3fr',
            header: {
              onSort: sortHandler('cardComment', setFilterParams),
              sorting: sortChecker('cardComment', filterParams),
              testId: 'header_costCenter',
              node: () => <Box fw='800'>{_t('Cost center')}</Box>,
            },
            cell: {
              node: (record) => <Box fw='800'>{record.cardComment || '-'}</Box>,
            },
          },

          {
            width: '3fr',
            header: {
              onSort: sortHandler('startTime', setFilterParams),
              sorting: sortChecker('startTime', filterParams),
              testId: 'header_time',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Start')}</Box>
                  <Box>{_t('End')}</Box>
                  <Box>{_t('Duration')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => <SessionTimeCell startTime={record.startTime} endTime={record.endTime} />,
            },
          },

          {
            width: '2fr',
            header: {
              onSort: sortHandler('rateServiceType', setFilterParams),
              sorting: sortChecker('rateServiceType', filterParams),
              testId: 'header_service',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Used')}</Box>
                  <Box fw='800'>{_t('Service')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center'>
                  <Badge testId={'el_service'} fg={contrastColor(rateTypeColor(record.rateServiceType))} bg={rateTypeColor(record.rateServiceType)}>
                    {rateTypeTranslation(record.rateServiceType)}
                  </Badge>
                </Box>
              ),
            },
          },

          {
            width: '2fr',
            header: {
              onSort: sortHandler('energy', setFilterParams),
              sorting: sortChecker('energy', filterParams),
              testId: 'header_energy',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Charged')}</Box>
                  <Box fw='800'>{_t('Energy')}</Box>
                  <Box>{_t('(kWh)')}</Box>
                </Box>
              ),
            },
            subHeader: {
              node: () => (
                <Box kind={'vflex'} justify='center' align='flex-end' fw='700'>
                  {!sumResp ? <SpinnerIco /> : <div>{formatEnergy(sumResp.totalEnergy, user?.preferences.languageCode, 2)}</div>}
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center' align='flex-end' testId='el_energy'>
                  {formatEnergy(record.energy, user?.preferences.languageCode, 2)}
                </Box>
              ),
            },
          },

          {
            width: '2fr',
            header: {
              onSort: sortHandler('totalCost', setFilterParams),
              sorting: sortChecker('totalCost', filterParams),
              testId: 'header_totalCost',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Cost')}</Box>
                  <Box>{_t('(€)')}</Box>
                </Box>
              ),
            },
            subHeader: {
              node: () => (
                <Box kind={'vflex'} justify='center' align='flex-end' fw='700'>
                  {!sumResp ? <SpinnerIco /> : <div>{formatPrice(sumResp.totalCost, user?.preferences.languageCode)}</div>}
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center' align='flex-end' testId='el_cost'>
                  {formatPrice(record?.totalCost, user?.preferences.languageCode, 2)}
                </Box>
              ),
            },
          },

          {
            width: '2fr',
            header: {
              testId: 'header_status',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Status')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center' testId='el_status' gap='xs'>
                  <DotBadge circleColor={SESSION_STATUS_COLORS[record.status]}>{sessionStatusTranslation(record.status)}</DotBadge>
                  {record.status === SessionStatus.Charging && record.soc && (
                    <>
                      <Divider kind='h' />
                      <Box kind={'hflex'} gap='xs' align='center'>
                        <Ico file={<BatteryChargingIco />} size='24px' />
                        <Box fw='600'>{record.soc.toFixed(0)}%</Box>
                        <Box>(SoC)</Box>
                      </Box>
                    </>
                  )}
                </Box>
              ),
            },
          },
        ];

        return result;
      }}
    />
  );
}
